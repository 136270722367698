import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">沪网文 [2017] 3638-239号 　　<a href="https://beian.miit.gov.cn">工信部备案：沪ICP备14024114号-1</a>   </p>
        <p className="copyright">地址：上海市闵行区莘建东路58弄1号1312室　　<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202007322">沪公网安备：31011202007322</a></p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
