import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/人物头像.png'
import pic02 from '../images/xinwen_bg.jpg'
import pic03 from '../images/gonglue_bg.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">公司介绍</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <p>&emsp;&emsp;上海班图网络科技有限公司专注于自主研发好玩有趣有文化的战棋游戏，经过近2年的快速发展，已经研发并上线了40款战棋游戏，申请了三十多款软件著作权以及专利，并打造了最大战棋游戏品牌——同人战棋。</p>
          <p>&emsp;&emsp;同人战棋不仅仅定位于战棋游戏的研发和发行，更在倡导"历史为骨，游戏为皮，华夏文明，百世传承"的产品理念，同人战棋正在用自己对战棋的理解和热爱，去不断创作更有文化沉淀的精品。</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p>Adipiscing magna sed dolor elit. Praesent eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam erat volutpat. Praesent urna nisi, fringila lorem et vehicula lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.</p>
          <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat tempus.</p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">同人游戏许可及服务协议</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>&emsp;&emsp;《同人游戏许可及服务协议》（以下简称“本协议”）由您与同人游戏服务提供方共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制同人游戏责任的条款（以下称“免责条款”）、对用户权利进行限制的条款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款，以及开通或使用某项服务的单独协议。前述免责、限制及争议解决方式和管辖条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，包括但不限于本协议第二条、第三条、第四条、第六条、第九条等相关条款，您对该等条款的确认将可能导致您在特定情况下的被动、不便、损失，请您在确认同意本协议之前或在使用同人游戏服务之前再次阅读前述条款。双方确认前述条款并非属于《合同法》第40条规定的“免除其责任、加重对方责任、排除对方主要权利的”的条款，并同意该条款的合法性及有效性。</p>
          <p>&emsp;&emsp;除非您已阅读并接受本协议所有条款，否则您无权使用同人游戏服务。如果您对本协议或同人游戏服务有意见或建议，可与同人游戏客户服务部门联系，我们会给予您必要的帮助。您点击同意、接受或下一步，或您注册、使用同人游戏服务均视为您已阅读并同意签署本协议。</p>
          <p>&emsp;&emsp;如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</p>
          <p><a href="https://www.trgame.cn/service.htm">协议细节</a></p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">联系我们</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p>
            地址：上海市闵行区莘建东路58弄1号1312室<br />
            电话：021-62215935
          </p>
          <p>
            人力资源联系<br />
            人力资源部<br />
            邮箱：hr@bantus.cn
          </p>
          <p>
            商务合作联系<br />
            商务合作部<br />
            邮箱：bantu@bantus.cn
          </p>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main