import React from 'react'
import PropTypes from 'prop-types'

import logo2 from '../images/logo2.png'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <img src={logo2} alt="" />
        <div className="content">
            <div className="inner">
                <h1>天火同人</h1>
                <p>历史为骨，游戏为皮，华夏文明，百世传承<br />
                    为中华之崛起而做游戏
                </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>公司介绍</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>服务协议</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>联系我们</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
